<template>
    <div>
      <div
          class="subheader subheader-transparent fixed"
          v-bind:class="subheaderClasses"
          id="kt_subheader"
      >
        <div
            class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
            v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
        >
          <div class="d-flex align-items-baseline mr-5">
            <!--begin::Page Title-->
            <h2 class="subheader-title my-2 mr-3">
              <template v-if="currentRouteName == 'hvf costs'">H&B Costs</template>
              <template v-else>H&B Reports</template>
            </h2>
  
            <ul v-if="currentRouteName == 'hvf costs'" class="nav-items custom_nav_item">
              <li>
                <router-link to="/hvf/costs?head=Cost-of-Sales">
                  <span>Cost of Sales</span>
                </router-link>
              </li>
              <li>
                <router-link to="/hvf/costs?head=selling-distribution">
                  <span>Selling & Distribution Expenses</span>
                </router-link>
              </li>
              <li>
                <router-link to="/hvf/costs?head=administrative-expenses">
                  <span>Administrative Expenses</span>
                </router-link>
              </li>
              <li class="custom_dropdown_menu">
                <b-dropdown
                    size="sm"
                    class="m-2"
                    toggle-class="custom_dropdown_menu"
                    id="dropdown-right"
                    variant="primary"
                    exact-path="true" 
                    exact-path-active-class="item_active" 
                    right
                    no-caret
                   
                >
                  <template #button-content>
                    <span class="name">
                      <i class="fas fa-ellipsis-h"></i>
                    </span>
                  </template>
                  <b-dropdown-item href="/hvf/costs?head=financial-expenses">Financial Expenses</b-dropdown-item>
                  <b-dropdown-item href="/hvf/costs?head=exchange-gain-loss">Exchange Gain & Loss</b-dropdown-item>
                  <b-dropdown-item href="/hvf/costs?head=other-income-loss">Other Income / Loss</b-dropdown-item>
                  <b-dropdown-item href="/hvf/costs?head=other-comprehensive">Other Comprehensive Income / Loss</b-dropdown-item>
                </b-dropdown>
              </li>
            </ul>
            <ul v-else class="nav-items">
              <li :class="(currentRouteName == 'hvf retail')?'active':''">
                <router-link to="/hvf/retail/reports">

                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 254 301" style="enable-background:new 0 0 254 301;" xml:space="preserve">
                      <g>
                        <path class="icon st4" d="M205.1,70.5c-0.7,3.4-1.4,6.7-2.1,10c-0.6,2.9-1.2,5.8-1.8,8.6c-1,5-2,9.9-3.1,14.9
                          c-1.8,8.8-3.7,17.7-5.5,26.5c-1,5-2.1,9.9-3.1,14.9c-1.1,5.5-2.3,11-3.4,16.5c-1.1,5.3-2.2,10.7-3.3,16c-0.1,0.5,0,0.8,0.3,1.1
                          c3.7,4.3,5,9.2,3.9,14.7c-1.4,6.7-6.8,11.9-13.6,13.1c-1.1,0.2-2.2,0.3-3.3,0.3c-38.6,0-77.2,0-115.8,0c-5.1,0-8-4.8-6.3-8.7
                          c1-2.4,3-3.9,5.6-3.9c12.1,0,24.1,0,36.2,0c26.8,0,53.5,0,80.3,0c2.4,0,4.1-1.3,4.5-3.5c0.5-2.3-1.1-4.6-3.4-4.9
                          c-0.5-0.1-0.9-0.1-1.4-0.1c-40.1,0-80.2,0-120.3,0c-3.1,0-5.4-1.5-6.3-4.2c-0.3-1-0.5-2-0.7-3c-0.8-3.9-1.6-7.8-2.4-11.8
                          c-1.8-8.6-3.6-17.1-5.3-25.7c-1.7-8-3.3-16-5-24c-1.2-5.6-2.4-11.2-3.5-16.9c-0.2-1-0.4-2.1-0.6-3.1c-0.2-2,0.6-3.7,2-5.1
                          c1.2-1.3,2.8-1.8,4.5-1.8c20.1,0,40.1,0,60.2,0c3.2,0,6.4,0,9.6,0c2,0,4.1,0.1,6.1,0.1c1.4,0,2.9-0.1,4.3-0.1c2.6,0,5.1,0,7.7,0
                          c1.8,0,3.6,0.1,5.3,0.1c2.5,0,5.1-0.1,7.6-0.1c1.7,0,3.3,0.1,5,0.1c16.4,0,32.7-0.1,49.1-0.1c0.6,0,0.9-0.1,1-0.8
                          c1.8-8.8,3.6-17.5,5.4-26.3c0.7-3.4,3.1-5.4,6.5-5.4c7.8,0,15.7,0,23.5,0c3.5,0,6.3,2.7,6.3,6.2c0,3.6-2.7,6.3-6.3,6.3
                          c-5.8,0-11.7,0-17.5,0C205.9,70.5,205.5,70.5,205.1,70.5z M81.8,131.8c8.3,0,16.4,0,24.5,0c0-9.6,0-19.1,0-28.7
                          c-9.3,0-18.5,0-27.8,0C79.5,112.7,80.7,122.3,81.8,131.8z M119,103.1c0,9.6,0,19.1,0,28.7c8.2,0,16.3,0,24.5,0
                          c1.1-9.6,2.2-19.1,3.3-28.7C137.5,103.1,128.3,103.1,119,103.1z M156.2,131.9c0.4,0,0.6,0,0.9,0c7.1,0,14.2,0,21.4,0
                          c0.9,0,1.1-0.3,1.3-1.1c1.6-7.9,3.2-15.7,4.9-23.6c0.3-1.4,0.5-2.7,0.8-4.1c-8.7,0-17.3,0-25.9,0
                          C158.4,112.7,157.3,122.2,156.2,131.9z M69,131.8c0-0.3,0-0.6,0-0.8c-0.2-1.4-0.3-2.9-0.5-4.3c-0.9-7.6-1.8-15.2-2.6-22.8
                          c-0.1-0.7-0.3-0.9-1-0.9c-8,0-16,0-24,0c-0.3,0-0.6,0-1,0c2,9.7,4,19.2,6,28.7C53.6,131.8,61.2,131.8,69,131.8z M106.3,173.3
                          c0-9.6,0-19.1,0-28.7c-7.7,0-15.3,0-22.9,0c0,0.1-0.1,0.1-0.1,0.2c0.4,3.4,0.7,6.7,1.1,10.1c0.4,3.3,0.8,6.7,1.2,10
                          c0.3,2.6,0.6,5.1,0.9,7.7c0.1,0.7,0.4,0.8,0.9,0.8c6,0,12,0,18,0C105.7,173.3,106,173.3,106.3,173.3z M119,144.6
                          c0,9.6,0,19.1,0,28.7c0.1,0,0.2,0.1,0.3,0.1c6.2,0,12.5,0,18.7,0c0.5,0,0.6-0.2,0.7-0.7c0.3-2.4,0.5-4.7,0.8-7.1
                          c0.7-5.8,1.3-11.5,2-17.3c0.1-1.2,0.3-2.5,0.4-3.8C134.3,144.6,126.7,144.6,119,144.6z M48.4,144.6c0.1,0.8,0.2,1.5,0.4,2.2
                          c0.8,3.6,1.5,7.1,2.3,10.7c1.1,5,2.1,10,3.1,15c0.1,0.6,0.3,0.8,1,0.8c5.9,0,11.8,0,17.7,0c0.3,0,0.6,0,1,0
                          c-1.1-9.7-2.2-19.2-3.3-28.8C63.1,144.6,55.8,144.6,48.4,144.6z M176.9,144.6c-7.4,0-14.7,0-22.2,0c-1.1,9.6-2.2,19.1-3.3,28.7
                          c0.3,0,0.4,0.1,0.5,0.1c6.1,0,12.3,0,18.4,0c0.6,0,0.6-0.3,0.7-0.7c1-4.8,2.1-9.6,3.1-14.4c0.7-3.4,1.4-6.8,2-10.1
                          C176.4,146.9,176.6,145.8,176.9,144.6z" />
                        <path class="icon st4" d="M94.1,232.4c-0.1,11.4-9.5,20.1-20.3,19.9c-10.9-0.1-19.8-9.1-19.7-20.1c0-11,9-19.9,20-19.9
                          C84.7,212.4,94,220.8,94.1,232.4z M66.7,232.4c0,4.1,3.4,7.4,7.5,7.3c4.1-0.1,7.4-3.4,7.3-7.5c-0.1-4-3.4-7.3-7.4-7.3
                          C70,225,66.7,228.4,66.7,232.4z"/>
                        <path class="icon st4" d="M151.2,212.4c11,0,20,8.9,20,19.9c0,10.9-8.9,19.9-19.6,20c-11.2,0.2-20.3-8.9-20.4-20
                          C131.1,221.4,140.7,212.2,151.2,212.4z M143.8,232.3c0,4.1,3.3,7.4,7.3,7.4c4,0,7.4-3.3,7.4-7.2c0-4.1-3.2-7.5-7.3-7.5
                          C147.2,224.9,143.8,228.2,143.8,232.3z"/>
                      </g>
                      </svg>
                  <span>Retail</span>
                </router-link>
              </li>
              <li :class="(currentRouteName == 'hvf corporate')?'active':''">
                <router-link to="/hvf/corporate/reports">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 254 301" style="enable-background:new 0 0 254 301;" xml:space="preserve">
<g>
	<path class="icon st4" d="M235,244.2c-1.4,3.5-3.5,6.3-7.4,7.2c-0.9,0.2-1.9,0.3-2.9,0.3c-64.5,0-129,0-193.6,0c-6,0-10.2-4-10.2-9.8
		c0-26.7,0-53.4,0-80.1c0-0.3,0-0.7,0.1-1.3c3.7,0.8,7.2,1.6,10.8,2.4c25.5,5.8,50.9,11.6,76.4,17.3c2.2,0.5,2.9,1.3,2.8,3.5
		c-0.2,4.2-0.1,8.5-0.1,12.7c0,4.6,1.7,6.3,6.3,6.3c7.2,0,14.5,0,21.7,0c4.2,0,6-1.8,6-6c0-4.5,0.1-9.1,0-13.6
		c0-1.7,0.4-2.5,2.2-2.9c28-6.3,56-12.6,84-18.9c1.3-0.3,2.6-0.6,3.9-0.9C235,188.5,235,216.3,235,244.2z"/>
	<path class="icon st4" d="M235,148.1c-7.5,1.7-15,3.5-22.5,5.2c-22.2,5-44.3,10-66.5,15c-0.3,0.1-0.5,0.1-1,0.1c0-2.7,0-5.2,0-7.8
		c0-4.6-1.8-6.3-6.3-6.3c-7.2,0-14.4,0-21.5,0c-4.4,0-6.2,1.8-6.2,6.2c0,2.6,0,5.1,0,8c-30.1-6.8-60-13.6-90-20.4
		c0-6.3,0-12.4,0-18.5c0-4.6,0-9.2,0-13.8c0-5.8,4-9.8,9.8-10c2,0,4,0,6.1,0c61.9,0,123.7,0,185.6-0.1c6.1,0,10.4,1.7,12.6,7.6
		C235,124.9,235,136.5,235,148.1z"/>
	<path class="icon st4" d="M90.3,96c-3.8,0-7.3,0-10.9,0c-0.1-0.1-0.3-0.2-0.3-0.3c0.1-5.8-0.4-11.6,0.5-17.3
		c1.7-11.3,12.1-19.8,23.5-19.8c16.6-0.1,33.2-0.1,49.7,0c12.9,0.1,23.7,10.7,24,23.5c0.1,4.6,0,9.2,0,13.9c-3.8,0-7.5,0-11.4,0
		c0-3.9,0.2-7.7-0.1-11.5c-0.2-2.7-0.8-5.6-2-8c-2-3.9-5.5-6.2-9.9-6.3c-16.7-0.2-33.4-0.3-50.1-0.1c-7.9,0.1-12.9,5.7-13,13.9
		c-0.1,3.3,0,6.5,0,9.8C90.4,94.6,90.4,95.3,90.3,96z"/>
</g>
</svg>
                  <span>Corporate</span>
                </router-link>
              </li>
              <li :class="(currentRouteName == 'hvf export')?'active':''">
                <router-link to="/hvf/export/reports">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 254 301" style="enable-background:new 0 0 254 301;" xml:space="preserve">

<g>
	<path class="icon st4" d="M54,250.4c21.3,0,43.2,0,65.2,0c0.7,3.2,1.3,6.1,2,9.5c-2,0-3.9,0-5.9,0c-26,0-52,0-78,0
		c-14.5,0-22.7-8.2-22.9-22.7c0-3.2,0-6.4,0-9.6c0-5.6,1.3-6.9,6.9-7c7.8,0,15.6-0.1,23.4-0.1c0.2,0,0.5-0.2,1.2-0.4
		c0-1.7,0-3.7,0-5.6c0-47.6,0-95.1,0-142.7c0-10.2,5.7-18.1,14.9-20.6c2.5-0.7,5.2-0.9,7.8-0.9c44.6-0.1,89.3-0.1,133.9,0
		c9.9,0,17,4.6,20.1,12.7c2.9,7.7,0.8,16.2-5.4,21.7c-3.8,3.4-8.3,4.9-13.4,4.9c-3.6,0-7.2,0-11.2,0c0,10.6,0,20.8,0,31.4
		c-3.4,0-6.3,0-9.8,0c0-10.3,0-20.5,0-31.1c-1.9-0.1-3.3-0.2-4.8-0.2c-31,0-62,0-93,0c-6.9,0-7.7-0.8-7.7-7.6c0-3.5,0.1-7,0-10.4
		c-0.2-6.5-4.9-11.5-10.8-11.5c-6.2-0.1-10.9,4.9-10.9,11.7c0,53.5,0,107.1-0.1,160.6C55.6,238.2,54.6,244.1,54,250.4z"/>
	<path class="icon st4" d="M130,195.5c0-17.8,0-35.6,0-53.4c0-7.8,2.7-10.4,10.6-10.4c24.5,0,48.9,0,73.4,0c6.9,0,10,2.9,10,9.8
		c0.1,36.2,0.1,72.3,0,108.5c0,6.8-3.2,10-10,10c-24.6,0-49.2,0-73.8,0c-7.2,0-10.1-3-10.2-10.2C129.9,231.6,130,213.6,130,195.5z
		 M177.1,142.2c-10.6,0-21.1,0.1-31.7,0c-3.6,0-5.1,1.4-5,4.9c0.1,5,0.1,10,0,15c-0.1,3.5,1.3,4.9,4.9,4.9c21-0.1,41.9-0.1,62.9,0
		c3.9,0,5.2-1.7,5.1-5.3c-0.1-4.7-0.1-9.5,0-14.2c0.1-3.9-1.6-5.5-5.5-5.4C197.7,142.3,187.4,142.2,177.1,142.2z M204.1,249.5
		c9.4,0,9.3,0,9.4-8.8c0.1-11.1-0.5-9.6-9.8-9.6c-8.8,0-8.7,0-8.8,8.8C194.6,251.4,195.1,249.3,204.1,249.5z M204.1,204.3
		c-9.3,0-9.3,0-9.3,8.8c-0.1,10.8,0.1,9.5,9.4,9.5c9.2,0,9.1,0,9.2-9C213.6,202.9,212.9,204.3,204.1,204.3z M149.7,222.6
		c0.7,0,1.4,0,2.1,0c7.3,0,7.4-0.1,7.4-7.5c0-0.4,0-0.8,0-1.3c0.2-10.8-0.2-9.7-9.8-9.7c-8.8,0-8.8,0-8.9,8.9
		C140.4,223.8,140.8,222.7,149.7,222.6z M149.8,249.5c8.6-0.1,9.5,1.9,9.4-9.1c-0.1-9.3,0-9.3-9.3-9.3c-9.4,0-9.3,0-9.4,9.2
		C140.4,251.6,141.2,249.3,149.8,249.5z M176.9,204.3c-9.3,0-9.2,0-9.3,8.9c0,10.3-0.4,9.5,9.5,9.5c9.3,0,9.1,0.7,9-9.1
		C186.1,203.6,186.8,204.2,176.9,204.3z M176.8,249.5c9.4,0,9.4,1.3,9.4-9.1c0-10.1,0.2-9.3-9.3-9.3c-9.2,0-9.1,0-9.2,9.4
		C167.6,250.6,167.8,249.5,176.8,249.5z M149.6,195.6c9.6,0,9.6,0,9.6-9c0-9.4,0-9.4-9.3-9.3c-9.4,0-9.4,0-9.3,9.3
		C140.6,195.7,140.6,195.7,149.6,195.6z M186.1,186.8c0-1.1,0-2.2,0-3.3c0-5.9-0.3-6.2-6.1-6.2c-2.4,0-4.7,0.1-7.1,0
		c-3.9-0.2-5.8,1.3-5.4,5.4c0.1,1.1,0,2.2,0,3.3c-0.1,10.6-0.5,9.7,9.7,9.7C186.1,195.6,186.1,195.6,186.1,186.8z M203.6,195.6
		c9.8,0,9.8,0,9.8-9.2c0-9.1,0-9.1-9.1-9.1c-9.5,0-9.5,0-9.5,9.5C194.7,195.6,194.7,195.6,203.6,195.6z"/>
	<path class="icon st4" d="M86.2,206.2c-7-2.3-12.2-6.3-14.9-13.2c-1.2-3-1.4-6,2.1-7.4c3.3-1.4,5.4,0.2,6.8,3.5
		c2.6,6.2,8.3,9.1,14.1,7.5c5.4-1.5,9.2-6.9,8.7-12.3c-0.6-6.1-5.5-10.4-12.3-10.8c-9.5-0.6-16.6-6.7-18.3-15.8
		c-1.7-8.9,2.7-17.2,11.2-21c1.9-0.9,2.9-1.7,3.3-4.1c0.3-1.6,2.7-3.8,4.2-3.8c1.6,0,4.4,2,4.6,3.4c0.4,2.9,2.2,3.5,4,4.9
		c3.4,2.7,6.5,5.9,9,9.4c1.8,2.5,1.7,5.8-1.8,7.4c-3.3,1.5-5.4-0.1-6.9-3.3c-1.9-4.2-5.8-6.4-9.6-6c-4,0.4-7.7,3.6-8.6,7.5
		c-0.9,4.1,0.4,7.6,3.9,9.8c1.8,1.1,4.1,1.8,6.2,2c11.4,1.4,18.8,7.9,20.4,18.1c1.6,10.1-3.5,18.5-14,23.4c-0.9,0.4-2.2,1.5-2.2,2.4
		c-0.3,3.3-1.7,5.3-5.2,5.2c-3.6-0.1-4.6-2.6-4.4-5.8C86.5,207,86.4,206.8,86.2,206.2z"/>
	<path class="icon st4" d="M119,113.4c-15,0-30,0-45,0c-3.4,0-5.8-0.9-6.2-4.5c-0.3-3.1,2.1-5.1,6.2-5.1c30.1,0,60.3-0.1,90.4,0.1
		c2,0,4.7,1.3,5.8,2.9c2.3,3.3-0.6,6.6-5.3,6.6C149.5,113.4,134.2,113.4,119,113.4z"/>
	<path class="icon st4" d="M92.4,226.2c6.2,0,12.5,0.1,18.7,0c3.4,0,5.9,1.1,6,4.7c0,3.6-2.5,5-5.9,5c-12.5,0-25,0.1-37.5,0
		c-3.3,0-5.8-1.3-6.1-4.9c-0.2-3,2.2-4.8,6.4-4.8C80.2,226.2,86.3,226.3,92.4,226.2z"/>
</g>
</svg>

                  <span>Export</span>
                </router-link>
              </li>
              <li :class="(currentRouteName == 'occ call center')?'active':''">
                <router-link to="#">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 254 301" style="enable-background:new 0 0 254 301;" xml:space="preserve">

<g id="badge">
	<path class="st5" d="M65.4,181.4l-40.5,70.2l38.7-2.3l14.5,33l42.6-73.8c-4.1-2.6-8.1-7.1-12.2-8c-6.8-1.5-16.8,3.8-23.1,0.9
		c-5.6-2.5-6.8-12.8-11.6-16.5C71.6,183.2,68.6,182.3,65.4,181.4L65.4,181.4z M65.4,181.4"/>
	<path class="st5" d="M188.9,180.5c-1.8,0.5-3.4,1.2-4.6,2.1c-5.4,4.3-7.5,15.3-13.6,18.4c-5.4,2.7-14.4-2.7-20.3-1.4
		c-5.2,1.2-10.4,6.9-15.7,9.5l42.2,73.2l14.5-33l38.7,2.3L188.9,180.5z M188.9,180.5"/>
	<path class="st5" d="M166.6,194.6c-5.2,2.6-13.6-2.6-19.2-1.3c-6.4,1.5-12.7,10-19.3,10.2c-5.8,0.1-11.1-8.2-16.8-9.4
		c-6.4-1.4-15.9,3.6-21.9,0.9c-5.3-2.4-6.5-12.1-11-15.6c-5.2-4-15.9-3.6-20.1-8.6c-3.7-4.4-0.5-13.7-3-18.8
		c-2.9-5.8-12.8-10-14.4-16.3c-1.4-5.5,5.6-12.5,5.5-18.2c0-6.5-7.1-14.5-5.8-20.8c1.2-5.6,10.5-8.9,13-14c2.8-5.8,0-16.1,4-21.2
		c3.5-4.5,13.4-3.5,17.9-7.1c5.1-4.1,7.1-14.5,12.9-17.4c5.2-2.6,13.6,2.6,19.2,1.3c6.4-1.5,12.7-10,19.3-10.2
		c5.8-0.1,11.1,8.2,16.8,9.4c6.4,1.4,15.9-3.6,21.9-0.9c5.3,2.4,6.5,12.1,11,15.6c5.2,4,15.9,3.6,20.1,8.6c3.7,4.4,0.5,13.7,3,18.8
		c2.9,5.8,12.8,10,14.4,16.3c1.4,5.5-5.6,12.5-5.5,18.2c0,6.5,7.1,14.5,5.8,20.8c-1.2,5.6-10.5,8.9-13,14c-2.8,5.8,0,16.1-4,21.2
		c-3.5,4.5-13.4,3.5-17.9,7.1C174.4,181.3,172.5,191.7,166.6,194.6L166.6,194.6z M166.6,194.6"/>
	<path class="st6" d="M198,115.8c0,18.7-7.4,36.7-20.7,49.9c-13.2,13.2-31.2,20.7-49.9,20.7c-18.7,0-36.7-7.4-49.9-20.7
		c-13.2-13.2-20.7-31.2-20.7-49.9c0-18.7,7.4-36.7,20.7-49.9c13.2-13.2,31.2-20.7,49.9-20.7c18.7,0,36.7,7.4,49.9,20.7
		C190.6,79.1,198,97.1,198,115.8L198,115.8z M198,115.8"/>
	<path class="st5" d="M189.1,115.8c0,16.4-6.5,32-18.1,43.6c-11.6,11.6-27.3,18.1-43.6,18.1s-32-6.5-43.6-18.1
		c-11.6-11.6-18.1-27.3-18.1-43.6c0-16.4,6.5-32,18.1-43.6c11.6-11.6,27.3-18.1,43.6-18.1s32,6.5,43.6,18.1
		C182.6,83.7,189.1,99.4,189.1,115.8L189.1,115.8z M189.1,115.8"/>
	<path class="st6" d="M86.7,116.6l19.7,19.7l10.3,10.3l10.3-10.3l41.2-41.2l-10.3-10.3l-41.2,41.2L97,106.3L86.7,116.6z M86.7,116.6
		"/>
</g>
</svg>
                  <span>Quality Control</span>
                </router-link>
              </li>
              <li>
                <router-link to="#">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                       viewBox="0 0 268.09 263.49" style="enable-background:new 0 0 268.09 263.49;" xml:space="preserve">
                  <g>
                    <path d="M218.06,45.58H57.28c-10.21,0-18.57,8.36-18.57,18.57v160.78c0,10.21,8.36,18.57,18.57,18.57h160.78
                      c10.21,0,18.57-8.36,18.57-18.57V64.14C236.63,53.93,228.28,45.58,218.06,45.58z M215.45,222.31H59.9V66.76h155.55V222.31z" class="icon"/>
                    <path d="M237.15,91.29h-96.92c-6.16,0-11.19,4.57-11.19,10.15v87.86c0,5.58,5.04,10.15,11.19,10.15h96.92
                      c6.16,0,11.19-4.57,11.19-10.15v-87.86C248.34,95.86,243.3,91.29,237.15,91.29z M228.13,177.15h-79.57v-66.19h79.57V177.15z" class="icon"/>
                    <circle cx="182.55" cy="145.37" r="15.89" class="icon"/>
                  </g>
                  </svg>
                  <span>Accounts</span>
                </router-link>
              </li>
  
        
            </ul>
          </div>
        <div v-if="!currentRouteName == 'hvf costs'" class="subheader-search-box">
            <form
                @submit.prevent="userSearch"
                class="w-100 d-flex align-items-center mt-3"
            >
                <!-- <input type="text" class="form-control border-redius-0" v-model="search_text"
                    placeholder="Search Users: Exp: name, phone number" aria-label="Recipient's username"
                    aria-describedby="button-addon2">
                <button class="btn btn-info border-redius-0" type="submit" id="button-addon2">Search</button> -->

                <div class="input-icons">
                <i class="fas fa-search icon"> </i>
                <input class="input-field" type="text" placeholder="Search"/>
                </div>
            </form>
        </div>
  
        <div class="mr-15">
          <b-dropdown
              size="lg"
              class="m-2 custom-dropdown rounded-circle btn-circle"
              toggle-class="rounded-circle"
              id="dropdown-right"
              right
              no-caret
          >
            <template #button-content class="custom-dropdown">
              <span class="name">
                {{ getSingleLettter(currentUser.fullname) }}
              </span>
            </template>
            <b-dropdown-item-button
                @click="onLogout"
                class="custom-dropdown-item"
            >
              <div class="d-flex align-items-center justify-content-center">
                <span class="pr-5 pt-2"><i class="fas fa-sign-out-alt"></i></span>
                <span>Logout</span>
              </div>
            </b-dropdown-item-button>
          </b-dropdown>
        </div>
      </div>
    </div>
      <div class="m-8"></div>
    </div>
  </template>
  
  <script>
  import {mapGetters, mapActions} from "vuex";
  import axios from "axios";
  import {getSingleLettter} from "../../../healper/nameFormating";
  
  export default {
    name: "OccSubheader",
    data() {
      return {
        notifications: [],
        nameMenufulate: "",
        search_text: "",
      };
    },
    props: {
      breadcrumbs: Array,
      title: String,
    },
    mounted() {
      this.getUserPermissions();
    },
    computed: {
      ...mapGetters(["layoutConfig"]),
  
      /**
       * Check if subheader width is fluid
       */
      currentUser: {
        get() {
          return this.$store.state.auth.userData;
        },
      },
  
      currentRouteName() {
          return this.$route.name;
      },

      currentRoutePath() {
          return this.$route.path;
      },
  
      widthFluid() {
        return this.layoutConfig("subheader.width") === "fluid";
      },
  
      subheaderClasses() {
        const classes = [];
        const style = this.layoutConfig("subheader.style");
        if (style) {
          classes.push(style);
  
          if (style === "solid") {
            classes.push("bg-white");
          }
  
          if (this.layoutConfig("subheader.fixed")) {
            classes.push("border-top");
          }
  
          if (this.layoutConfig("subheader.clear")) {
            classes.push("mb-0");
          }
        }
        return classes.join(" ");
      },
    },
    methods: {
      ...mapActions("auth", ["getUserPermissions"]),
      getSingleLettter,
      onLogout() {
        this.$router.push("/login");
        localStorage.removeItem("api_token");
      },
  
      fullnameMenupulate() {
        var str = this.currentUser.fullname;
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        return matches.join(""); // JSON
      },
  
      getNotification() {
        axios
            .get(
                `${
                    process.env.VUE_APP_URL
                }/api/v2/bo-notification/list?api_token=${localStorage.getItem(
                    "api_token"
                )}`
            )
            .then((res) => {
              if (res.data.status_code == 400) {
                this.notifications = res.data.data;
              } else {
                this.notifications = res.data.data;
              }
            });
      },
      userSearch() {
        this.$root.$emit("search_text", this.search_text);
        const path = `/user/filter?search_text=${this.search_text}`;
  
        if (this.$route.path !== path) this.$router.push(path);
        // this.$root.$emit('router', this.search_text);
        else
          this.$router.push({
            path: "/user/filter",
            query: {search_text: this.search_text},
          });
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  #kt_subheader {
    background-color: #fff;
  
    .subheader-title {
      color: #002147;
      font-weight: 600;
    }
  
    .nav-items {
      list-style-type: none;
      margin-left: 20px;
      margin-bottom: 0;

      &.custom_nav_item{
        li{
          padding: 0 8px;
          a{
            padding: 10px 10px;
            background: transparent;
              border-radius: 100px;
              &:focus,
              &.router-link-exact-active{
              background: #004f3f;
              color: #FFD600;
              font-weight: 600;
            }
          }
        }
      }
  
      li {
        display: inline-block;
        padding: 6px 18px;
        font-size: 15px;
        font-family: Arial;
        color: #606060;
  
        a {
          color: inherit;
  
          svg {
            height: 30px;
          }
  
          i {
            color: #606060;
          }
  
          span {
            top: 2px;
            position: relative;
            padding-left: 5px;
          }
        }
  
  
        &.active {
          background-color: #00579b;
          color: #fff;
          border-radius: 20px;
  
  
          i {
            color: #fff;
          }
  
          .icon {
            fill: #fff;
          }
        }
  
        form {
          input {
            background-color: #d6d6d6;
            color: #fff;
            font-weight: 600;
            text-align: left;
            padding-left: 40px;
            border-radius: 20px;
            outline: none;
  
            &::placeholder {
              color: #fff;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .subheader-search-box form {
  
  input {
    background-color: #d6d6d6;
    color: #fff;
    font-weight: 600;
    text-align: left;
    padding-left: 40px;
    border-radius: 20px;
    outline: none;
    width: 200px;
    &::placeholder {
      color: #fff;
      font-weight: 600;
    }
  }
  
  
  }

  .custom_dropdown_menu {
    ul{
      display: flex;
      flex-direction: column;
      li{
        display: block !important;
      }
    }
  }
  </style>
  <style scoped>
  .dropdown-menu {
    left: -62px !important;
  }
  
  .notification {
    background: red;
    padding: 3px 8px;
    border-radius: 7px;
    position: relative;
    top: -13px;
    left: -6px;
    font-weight: 500;
  }
  
  .custom-notification-div {
    width: 200px !important;
    border-bottom: 1px solid #dddddd;
    /*overflow: hidden;*/
  }
  
  .custom-notification-div .dropdown-item {
    white-space: inherit !important;
  }
  
  .work-speacing {
    white-space: normal !important;
  }
  
  .custom-dropdown button {
    border-radius: 61px !important;
    padding: 9px 6px !important;
    font-weight: bold;
  }
  
  .custom-dropdown-item {
    top: 16px;
    left: -44px;
  }
  
  .dropdown-toggle {
    border-radius: 70px;
  }
  
  /deep/ .btn.btn-secondary {
    background: #fcd603;
    font-size: 25px;
    font-weight: bold;
    padding: 2px 9px;
    height: 56px;
    width: 56px;
    border-radius: 100%;
    overflow: hidden;
  }
  
  .border-redius-0 {
    border-radius: 0px;
  }
  
  .input-icons i {
    position: absolute;
    line-height: 1.3;
  }
  
  .input-icons {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .icon {
    padding: 8px 6px;
    color: #fff;
    min-width: 46px;
    text-align: center;
    font-size: 22px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
  
  .sub-header-icon {
    height: 25px;
  }
  .fixed {
    position: fixed;
      width: 79%;
      top: 0px;
      z-index: 1;
  }
  .hvf-icon {
    height: 30px;
  }

  /deep/ button.custom_dropdown_menu.btn.btn-primary{
    background: transparent;
    border: none;
  }
  /deep/ button.custom_dropdown_menu.btn.btn-primary:hover{
    background-color: #004E3F !important;
  }
  /deep/ button.custom_dropdown_menu.btn.btn-primary i{
    color: #000;
  }

  /deep/ .custom_dropdown_menu a.item_active{
            background: #004f3f;
              color: #FFD600;
              font-weight: 600;
          }
  </style>

  